<template>
	<v-card>
		<v-dialog v-model="dialogErro.status" persistent>
			<CardDialog :dialog="dialogErro" />
		</v-dialog>
		<v-card-title class="title">
			<v-row no-gutters class="mb-n6">
				<v-col class="d-none d-md-block pr-1">
					<InputDatePicker
						:disabled="carregando"
						v-model="busca.dtinicio"
						label="Data inicial"
						:outlined="true"
						:dense="true"
					/>
				</v-col>
				<v-col class="d-none d-md-block px-1">
					<InputDatePicker
						:disabled="carregando"
						v-model="busca.dtfim"
						label="Data final"
						:outlined="true"
						:dense="true"
					/>
				</v-col>
				<v-bottom-sheet class="d-flex d-md-none" v-model="sheet">
					<v-sheet class="text-center" height="100%">
						<v-row class="pa-3">
							<v-col cols="6" class="mb-n3 px-2">
								<InputDatePicker
									:disabled="carregando"
									v-model="busca.dtinicio"
									label="Data inicial"
									:outlined="true"
									:dense="true"
								/>
							</v-col>
							<v-col cols="6" class="mb-n3 px-2">
								<InputDatePicker
									:disabled="carregando"
									v-model="busca.dtfim"
									label="Data final"
									:outlined="true"
									:dense="true"
								/>
							</v-col>
							<v-col cols="6">
								<v-btn text class="mt-n3" color="primary" @click="sheet = !sheet">Cancelar</v-btn>
							</v-col>
							<v-col cols="6">
								<v-btn text class="mt-n3" color="primary" @click="listar(0), sheet = !sheet">Filtrar</v-btn>
							</v-col>
						</v-row>
					</v-sheet>
				</v-bottom-sheet>
				<v-col class="pl-1">
					<v-autocomplete
						no-filter
						v-model="busca.idcliente"
						outlined
						dense
						label="Cliente"
						:search-input.sync="searchMobile"
						:items="items"
						:loading="carregando"
						item-text="nome"
						item-value="idcliente"
						class="d-flex d-md-none"
						@keydown.enter="listar(0)"
						append-outer-icon="mdi-menu"
						@click:append-outer="sheet = !sheet"
					></v-autocomplete>
					<v-autocomplete
						no-filter
						v-model="busca.idcliente"
						outlined
						dense
						label="Cliente"
						:search-input.sync="search"
						:items="items"
						:loading="carregando"
						item-text="nome"
						item-value="idcliente"
						class="d-none d-md-block"
						@keydown.enter="listar(0)"
					></v-autocomplete>
				</v-col>
				<v-col cols="auto" class="ml-2">
					<v-btn
						:disabled="carregando"
						class="mt-1"
						color="primary"
						elevation="0"
						fab
						x-small
						@click="listar(0)"
					>
						<v-icon>mdi-magnify</v-icon>
					</v-btn>
				</v-col>
			</v-row>
		</v-card-title>
		<v-divider />
		<v-progress-linear indeterminate absolute :active="carregando" />
		<v-card-text>
			<v-card flat dark color="primary" class="mb-3">
				<v-card-text>
					<v-row>
						<v-col class="mb-n6 px-1">
							<v-autocomplete
								no-filter
								v-model="cliente.idcliente"
								outlined
								dense
								label="Novo cliente"
								:search-input.sync="searchCadastro"
								:items="items"
								:loading="carregando"
								item-text="nome"
								item-value="idcliente"
								class="d-none d-md-block"
								@keydown.enter="listar(0)"
							></v-autocomplete>
						</v-col>
						<v-col class="mb-n6 px-1">
							<v-autocomplete
								:items="filiaisFiltro"
								clearable
								item-text="filial"
								item-value="idfilial"
								label="Filial"
								outlined
								dense
								:disabled="carregando"
								v-model="cliente.idfilial"
							></v-autocomplete>
						</v-col>
						<v-col cols="auto" class="pl-2">
							<v-btn fab light small @click="inserirCliente()">
								<v-icon>mdi-plus</v-icon>
							</v-btn>
						</v-col>
					</v-row>
				</v-card-text>
			</v-card>
			<v-simple-table>
				<template v-slot:default>
					<thead>
						<tr>
							<th class="text-left">Filial</th>
							<th class="text-left">Cliente</th>
							<th class="text-left">Data de cadastro</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						<template v-if="dados.total > 0">
							<tr v-for="(u, i) in dados.lista" :key="i">
								<td>{{u.idfilial}} - {{u.filial}}</td>
								<td>{{u.idcliente}} - {{u.cliente}}</td>
								<td>{{u.dtmovimento}}</td>
								<td class="text-center">
									<v-btn fab x-small color="error" elevation="0" @click="excluirCliente(u)">
										<v-icon>mdi-minus</v-icon>
									</v-btn>
								</td>
							</tr>
						</template>
						<template v-else>
							<tr>
								<td class="text-center" colspan="4">Nenhum registro encontrado.</td>
							</tr>
						</template>
					</tbody>
				</template>
			</v-simple-table>
			<Paginacao
				:total="dados.total"
				@atualizar="listar"
				:carregando="carregando"
				:pg="pgSelecionada"
			/>
		</v-card-text>
		<v-progress-linear indeterminate absolute :active="carregando" />
	</v-card>
</template>


<script>
import axios from "axios";
import { mapState } from "vuex";
import CardDialog from "../Widgets/CardDialog";
import Paginacao from "../Widgets/Paginacao";
import InputDatePicker from "../Widgets/InputDatePicker.vue";
import mixinFilial from "../../util/mixinFilial";

export default {
	name: "ParcelamentoLiberacao",
	components: { CardDialog, InputDatePicker, Paginacao },
	mixins: [mixinFilial],
	data: () => ({
		step: 1,
		sheet: false,
		carregando: false,
		dados: { total: 0 },
		cliente: {},
		dialogErro: {
			status: false,
			title: "",
			msg: "",
			icon: "",
			iconColor: "",
		},
		busca: {
			dtinicio: `${new Date().getFullYear()}-${
				(new Date(
					new Date().getFullYear(),
					new Date().getMonth() + 1,
					0
				).getMonth() +
					1 <
				10
					? "0"
					: "") +
				(new Date().getMonth() + 1)
			}-01`,
			dtfim: `${new Date(
				new Date().getFullYear(),
				new Date().getMonth() + 1,
				0
			).getFullYear()}-${
				(new Date(
					new Date().getFullYear(),
					new Date().getMonth() + 1,
					0
				).getMonth() +
					1 <
				10
					? "0"
					: "") +
				(new Date().getMonth() + 1)
			}-${new Date(
				new Date().getFullYear(),
				new Date().getMonth() + 1,
				0
			).getDate()}`,
			idcliente: null,
			titulo: null,
		},
		aguardarBusca: "",
		nomeLimit: 50,
		clientes: [],
		search: null,
		searchMobile: null,
		searchCadastro: null,
		pgSelecionada: 0,
	}),
	computed: {
		...mapState(["backendUrl", "pgLimit", "timeLimit", "usuario"]),
		items() {
			if (this.clientes == null) return;

			this.clientes.forEach((cliente) => {
				if (cliente.nome.length > this.nomeLimit) {
					cliente.nome =
						cliente.nome.slice(0, this.nomeLimit) + "...";
				}
			});

			return this.clientes;
		},
		filiaisFiltro() {
			if (this.filiais.lista) {
				return this.filiais.lista.filter((v) => {
					return (
						v.idfilial != 6 &&
						v.idfilial != 12 &&
						v.idfilial != 25 &&
						v.idfilial != 29 &&
						v.idfilial != 33 &&
						v.idfilial != 37
					);
				});
			} else {
				return [];
			}
		},
	},
	methods: {
		carregarClientes(val) {
			clearTimeout(this.aguardarBusca);
			this.aguardarBusca = setTimeout(() => {
				this.carregando = true;
				return axios
					.post(`${this.backendUrl}cliente/listar`, {
						limit: 30,
						offset: 0,
						busca: val || null,
					})
					.then((res) => {
						this.clientes = res.data.lista;
						this.carregando = false;
					})
					.catch(() => {
						this.carregando = false;
					});
			}, this.timeLimit);
		},
		listar(pg) {
			this.pgSelecionada = pg;
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}parcelamento/cliente/listar`, {
					limit: this.pgLimit,
					offset: this.pgLimit * pg,
					dtini: this.busca.dtinicio || null,
					dtfim: this.busca.dtfim || null,
					idcliente: this.busca.idcliente || null,
				})
				.then((res) => {
					this.dados = res.data;
					this.carregando = false;
				})
				.catch(() => {
					this.carregando = false;
				});
		},
		inserirCliente() {
			if (!this.cliente.idcliente || !this.cliente.idfilial) {
				this.dialogErro.title = "Erro";
				this.dialogErro.msg = "Um ou mais campos não foram preenchidos";
				this.dialogErro.icon = "mdi-alert-circle-outline";
				this.dialogErro.iconColor = "red";
				this.dialogErro.status = true;
				return;
			}
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}parcelamento/cliente/inserir`, {
					idcliente: this.cliente.idcliente || null,
					idfilial: this.cliente.idfilial || null,
				})
				.then((res) => {
					this.carregando = false;
					if (res.data) {
						this.listar(0);
					} else {
						this.dialogErro.title = "Erro";
						if (res.data.erro) {
							this.dialogErro.msg = res.data.erro;
						} else {
							this.dialogErro.msg =
								"Não foi possível inserir o cliente";
						}
						this.dialogErro.icon = "mdi-alert-circle-outline";
						this.dialogErro.iconColor = "red";
						this.dialogErro.status = true;
					}
				})
				.catch((e) => {
					this.carregando = false;
					this.dialogErro.title = "Erro";
					if (e.response.data.erro) {
						this.dialogErro.msg = e.response.data.erro;
					} else {
						this.dialogErro.msg =
							"Não foi possível inserir o cliente";
					}
					this.dialogErro.icon = "mdi-alert-circle-outline";
					this.dialogErro.iconColor = "red";
					this.dialogErro.status = true;
				});
		},
		excluirCliente(cliente) {
			if (!cliente.idcliente) {
				return;
			}
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}parcelamento/cliente/excluir`, {
					idcliente: cliente.idcliente || null,
				})
				.then((res) => {
					this.carregando = false;
					if (res.data) {
						this.listar(0);
					} else {
						this.dialogErro.title = "Erro";
						if (res.data.erro) {
							this.dialogErro.msg = res.data.erro;
						} else {
							this.dialogErro.msg =
								"Não foi possível excluir o cliente";
						}
						this.dialogErro.icon = "mdi-alert-circle-outline";
						this.dialogErro.iconColor = "red";
						this.dialogErro.status = true;
					}
				})
				.catch((e) => {
					this.carregando = false;
					this.dialogErro.title = "Erro";
					if (e.response.data.erro) {
						this.dialogErro.msg = e.response.data.erro;
					} else {
						this.dialogErro.msg =
							"Não foi possível excluir o cliente";
					}
					this.dialogErro.icon = "mdi-alert-circle-outline";
					this.dialogErro.iconColor = "red";
					this.dialogErro.status = true;
				});
		},
		async init() {
			this.listar(0);
		},
	},
	watch: {
		search: function (val) {
			this.carregarClientes(val);
		},
		searchMobile: function (val) {
			this.carregarClientes(val);
		},
		searchCadastro: function (val) {
			this.carregarClientes(val);
		},
	},
	created() {
		this.init();
	},
};
</script>